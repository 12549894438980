import { NgModule, APP_INITIALIZER, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgbAlertModule, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './modules/auth/services/auth.service';
import { environment } from 'src/environments/environment';
import { HttpInterceptorService } from './modules/auth/services/http-interceptor';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { DecimalPipe } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { NgbDateCustomParserFormatter } from './providers/datepicker-formatter';
import { DndDirective } from './dnd.directive';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedService } from './services/shared.service';
import { RouterModule } from '@angular/router';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';

registerLocaleData(localeEs, 'es');

function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      //@ts-ignore
      authService.getUserData().subscribe().add(resolve);
    });
  };
}

function locale(shared: SharedService) {
  return shared?.getLanguage() || 'es';
}

@NgModule({
  declarations: [AppComponent, DndDirective],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    BrowserModule,
    BrowserAnimationsModule,   
    HttpClientModule,
    ClipboardModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    NgbAlertModule,  
    RouterModule,
    TranslateModule.forRoot(),  
    TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }) 
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService]      
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true
    },
    {
      provide: LOCALE_ID, 
      //useValue: 'es',
      useFactory: locale,
      deps: [SharedService]      
    },
    { 
      provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter 
    },
    DecimalPipe,    
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase }   
  ],
  bootstrap: [AppComponent],
  schemas: [
    NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA
  ]  
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
