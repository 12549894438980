import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getAuthFromLocalStorage } from '../modules/auth';
import { Observable, delay, of, retry, tap, throwError, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../modules/auth/services/auth.service';

export const PAGE = 0;
export const MAX_RESULTS = 25;
export interface QueryFilter {
    field: string;
    type?: string;
    value?: any;
    filterable?: boolean;
    role?: string;
    placeholder?: string;
    options?: QueryFilterOption[];
    data?: {
        path: string;
        property: string;
        value: string;
        label: string;
        options?: any[];
        order?: {
            field: string;
            direction: string;
        };
    }
}

export interface QueryFilterOption {
    value: string;
    label: string;
}

@Injectable({
  providedIn: 'root'
})
export class DataService {

    constructor(private http: HttpClient, private auth: AuthService) { }

    getRequestOptions(contentType?: string): any {
        const userData = getAuthFromLocalStorage();
        
        let token = '';
        if(userData && userData.auth) {
            token = userData.auth.token;
            const headers = new HttpHeaders({
                'Content-Type': contentType ? contentType : 'application/json',
                'Authorization': 'Bearer ' + token,
                'Access-Control-Allow-Origin': '*',
            });
    
            return {headers, responseType: contentType ? 'text' : 'json'};
        }        

        this.auth.logout();
        return undefined;
    }

    getRequestFileOptions(): {} {
        const userData = getAuthFromLocalStorage();
    
        let token = '';
        if(userData && userData.auth) {
            token = userData.auth.token;
        }

        const fileHeaders = new HttpHeaders({
            'Content-Type': 'multipart/form-data; boundary=file',
            'Authorization': 'Bearer ' + token,
            'Access-Control-Allow-Origin': '*',
            'Allow': 'GET, POST',
        });

        return {fileHeaders};
    }

    load(path: string, filters: QueryFilter[] = []): Observable<any> {        
        const options = this.getRequestOptions();
        if(options) {
            let params = '';
            if(filters.length) {                
                filters.filter(e => e.value).forEach(filter => {
                    if(params) {
                        params += '&' + filter.field + '=' + filter.value
                    }
                    else {
                        params += '?' + filter.field + '=' + filter.value
                    }                    
                });
            }
            return this.http.get<any>(environment.apiUrl + path + params, options); 
        }
        else {
            return of({});
        }    
    }

    list(path: string, filters: QueryFilter[] = []): Observable<any> {            
        const options = this.getRequestOptions();
        if(options) {   
            let params = '';
            if(filters.length) {                
                filters.filter(e => e.value !== undefined && e.value !== '').forEach(filter => {
                    if(params) {
                        params += '&' + filter.field + '=' + filter.value
                    }
                    else {
                        params += '?' + filter.field + '=' + filter.value
                    }                    
                });
            }
            return this.http.get<any>(environment.apiUrl + `${path}` + params, options);   
        }
        else {
            return of({});
        }     
    }

    post<T>(path: string, entity?: T): Observable<any> {        
        return this.http.post<T>(environment.apiUrl + path, entity, this.getRequestOptions());
    }

    postFile(path: string, image: File): Observable<any> {        
        const formData = new FormData();     
        formData.append('file', image, image.name);        
        return this.http.post(environment.apiUrl + path, formData, this.getRequestFileOptions());
    }

    put<T>(path: string, entity?: T): Observable<any> {        
        return this.http.put<T>(environment.apiUrl + path, entity, this.getRequestOptions());
    }

    get(path: string, contentType?: string): Observable<any> {       
        const options = this.getRequestOptions(contentType);
        if(options) {
            return this.http.get<any>(environment.apiUrl + path, options);
        }
        else {
            return of({});
        }   
    }

    retryGet(path: string, contentType?: string, config?: requestConfig, api?: APIVersion): Observable<any> {
        const options = this.getRequestOptions(contentType);
        if(options) {
            let endpoint = environment.apiUrl;
           if(api == APIVersion.PREPROCESSED) {
                endpoint = environment.preprocessedUrl;              
            }            
            return this.http.get<any>(endpoint + path, options).pipe(           
                tap(() => console.log(`GET success`, path)),
                retry({count: config?.count || 5, delay: (error, retryIndex) => {
                        let retry: any = throwError(() => error);                        
                        if (error.status === 0 || error.status === 429 || error.status === 500) {
                            console.log(`GET Error ${error.status}. Retry number ${retryIndex}:`, path)
                            retry = timer(2000);
                        }
                        else {
                            console.log(`GET Error ${error.status}. Not retry:`, path)
                        }
                        return retry;
                    }
                }),
                delay(config?.delay || 0)
            );
        }
        else {
            return of({});
        }   
    }

    delete(path: string, data?: any): Observable<any> { 
        let options: any = this.getRequestOptions();   
        if(data) {                
            options.body = data;
            return this.http.request('delete', environment.apiUrl + path, options);
        }
        else {
            return this.http.delete(environment.apiUrl + path, options);
        }
    }
   
}

export interface requestConfig {
    count?: number,
    delay?: number
}

export enum APIVersion {
    BACKEND = 0,
    PREPROCESSED = 1
}

