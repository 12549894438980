import { Device } from "./device.model";

export const KIT_TYPE_FIELWIZ = 'fieldwiz';
export const KIT_TYPE_ADMOSLIVE = 'admoslive';

export const KIT_TYPE_UBIKO_PRO = 'ubiko_pro';
export const KIT_TYPE_UBIKO_LIVE = 'ubiko_live';
export const KIT_TYPE_UBIKO_PLAYER = 'ubiko_player';
export const KIT_TYPE_UBIKO_DEV = 'ubiko_dev';

export class Kit {

    id: string;
    name: string; 
    type: string;  
    ubiko_type: string;
    club: any;
    devices: Device[];
    docking_station?: DockingStation;
    has_syncs?: boolean;    
    has_sync_jobs?: boolean;

    constructor() {}
}  

export interface DockingStation {
    serial_number: string;
    description: string;
    type: DockingStationType
}

export enum DockingStationType {
    USB_1 = 0,
    ASI = 1,
    UBIKO_PRO_V1 = 2,
    UBIKO_PRO_V2 = 3
}