import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { UserModel } from '../models/user.model';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { OptionalHeaders } from './auth-http/auth-http.service';

export type UserType = UserModel | undefined;
export const ROLE_ADMIN = 'admin';
export const ROLE_TECH = 'tech';
export const ROLE_MANAGER = 'manager';
export const ROLE_DISTRIBUTOR = 'distributor';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
    private unsubscribe: Subscription[] = [];  
    isLoading$: Observable<boolean>;
    currentUserSubject: BehaviorSubject<UserType>;
    isLoadingSubject: BehaviorSubject<boolean>;

    get currentUserValue(): UserType {      
        return this.currentUserSubject.value;
    }

    set currentUserValue(user: UserType) {
        this.currentUserSubject.next(user);
    }

    constructor(
        private authHttpService: AuthHTTPService,
        private router: Router
    ) {
        this.isLoadingSubject = new BehaviorSubject<boolean>(false);
        this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
        this.isLoading$ = this.isLoadingSubject.asObservable();
        const subscr = this.getUserData().subscribe();
        this.unsubscribe.push(subscr);
    }

    // public methods
    login(email: string, password: string): Promise<any> {
        this.isLoadingSubject.next(true);
        return this.authHttpService.login(email, password)
            .then(userData => {                  
                return userData;  
          })
          .catch(error => {
              console.log(error);            
              let userData = new UserModel();
              this.setAuthFromLocalStorage(userData); 
              this.isLoadingSubject.next(false);  
              userData.setError(error.code);
              return userData;
          }); 
    }

    getIdToken(response: any, optHeaders?: OptionalHeaders[]): Promise<UserModel> {    
        return this.authHttpService.getIdToken(response, optHeaders).then(userData => {
            this.currentUserSubject.next(userData);         
            return userData;
        });     
    } 

    logout(returnUrl?: string) {   
        this.authHttpService
            .logout()
            .then(() => {
                if(this.currentUserValue) {
                    this.currentUserValue.authenticated = false;
                    this.setAuthFromLocalStorage(this.currentUserValue); 
                    localStorage.removeItem(this.currentUserValue.uid + '_tech');
                }                         
                this.router.navigate(['/auth/login'], {
                    queryParams: { returnUrl },
                });
            })
            .catch((error) => {
                console.log('Logout error', error);
            });        
    }

    getUserData(): Observable<UserType> {
        const auth = getAuthFromLocalStorage();
        if (!auth || !auth.uid) {
          //return of(undefined);
        }
        this.currentUserSubject.next(auth);
        
        return of(auth);
    }

    // need create new user then login
    registration(user: UserModel): Observable<any> {
        this.isLoadingSubject.next(true);
        return this.authHttpService.createUser(user).pipe(
          map(() => {
            this.isLoadingSubject.next(false);
          }),
          switchMap(() => this.login(user.email, user.password)),
          catchError((err) => {
            console.error('err', err);
            return of(undefined);
          }),
          finalize(() => this.isLoadingSubject.next(false))
        );
    }

    forgotPassword(email: string): Observable<boolean> {
        this.isLoadingSubject.next(true);
        return this.authHttpService
          .forgotPassword(email)
          .pipe(finalize(() => this.isLoadingSubject.next(false)));
    }   

    // private methods
    public setAuthFromLocalStorage(user: UserModel): boolean {            
        if (user && user.uid) {
            if(user.auth) {
                delete user.auth.authTime;
                delete user.auth.issuedAtTime;
                delete user.auth.signInProvider;
                delete user.auth.signInSecondFactor;
            }    
            localStorage.setItem(authLocalStorageToken, JSON.stringify(user));
            return true;
        }
        else {
            localStorage.setItem(authLocalStorageToken, 'null');
            return false;
        }
    }

    public hasPermission(permission: string): boolean {
        let userData = getAuthFromLocalStorage();
        if(this.isAdmin()) return true;
        else if(userData && userData.permissions) {
            return userData.permissions.includes(permission);
        }
        return false;
    }

    public isSysAdmin(): boolean {
        let userData = getAuthFromLocalStorage();
        if(userData) {
            return userData.role == ROLE_ADMIN;
        }
        return false;
    }

    public isAdmin(): boolean {
        let userData = getAuthFromLocalStorage();
        if(userData) {
            return userData.role == ROLE_ADMIN || userData.role == ROLE_DISTRIBUTOR;
        }
        return false;
    }

    public isTech(): boolean {
        let userData = getAuthFromLocalStorage();
        if(userData) {
            return userData.role == ROLE_TECH;
        }
        return false;
    }

    public isManager(): boolean {
        let userData = getAuthFromLocalStorage();
        if(userData) {
            return userData.role == ROLE_MANAGER;
        }
        return false;
    }

    public isRole(role: string): boolean {
        switch (role) {
            case ROLE_ADMIN:
                return this.isAdmin();     
            case ROLE_TECH:
                return this.isTech();  
            case ROLE_MANAGER:
                return this.isManager();      
            default:
                return true;;
        }
    }

    public getUid(): any {
        let userData = getAuthFromLocalStorage();
        if(userData) {
            return userData.uid;
        }
        return undefined;
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }   
}

export const authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

export function getAuthFromLocalStorage(): UserType {
    try {
        const lsValue = localStorage.getItem(authLocalStorageToken);
        if (!lsValue) {
            return undefined;
        }
        return JSON.parse(lsValue);        
    } catch (error) {
        console.error(error);
        return undefined;
    }
}