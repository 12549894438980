import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MAX_RESULTS, PAGE, QueryFilter } from './data.service';

export const PARAMS_PAGE = 'page';
export const PARAMS_PAGESIZE = 'size';
export const PARAMS_SORT_COLUMN = 'order_by';
export const PARAMS_SORT_DIRECTION = 'order_direction';

@Injectable({
    providedIn: 'root'
})
export class DatatablePaginationService {

    constructor() { }

    // Paginacion
    page: number = PAGE;
    size: number = MAX_RESULTS;
    sortColumn: string;
    sortDirection: string;
   
    private _data$ = new BehaviorSubject<any[] | undefined>(undefined);
    private _total$ = new BehaviorSubject<number>(0);
    private _actionLoading$ = new BehaviorSubject<ActionLoading | undefined>(undefined);   
    private _newId$ = new BehaviorSubject<string | undefined>(undefined);

    get data$() { return this._data$.asObservable(); }
    get total$() { return this._total$.asObservable(); }
    get actionLoading$() { return this._actionLoading$.asObservable(); }
    get newId$() { return this._newId$.asObservable(); }

    setData(data: any[]) {
        this._data$.next(data);
        this._total$.next(data?.length || 0);
    }

    setActionLoading(action: ActionLoading | undefined) {
        this._actionLoading$.next(action);
    }

    getLink(link: string, id: string) {
        if(link && id) {
            return encodeURI(link.replace(':id', id));
        }
        return '';
    }

    setNewId(id?: string) {     
        this._newId$.next(id);      
    }

    reloadEntity(entity: any) {
        let elements: any[] = this._data$.value || [];
        if(elements) {
            elements[elements.findIndex(e => e.id == entity.id)] = entity;
            this._data$.next([...elements]);
        }        
    }
}

export interface DatatablePaginationAction {   
    id: string;
    tooltip?: string;
    type: string;
    action: string;  
    ficon?: string;
    class?: string;
    disabledCondition?: string[];
    items?: any;
    role?: string;
    permission?: string;
    navigate?: string;
    fields?: string[];
    license?: string;
    reload?: boolean;
    showLoading?: boolean;
}

export interface DatatablePaginationColumn {   
    name: string;
    label: string;
    direction?: string;
    role?: string;  
    sortable?: boolean;
    global?: boolean;
}

export interface DatatableConfiguration {
    entity: string;
    columns: DatatablePaginationColumn[];
    actions: DatatablePaginationAction[];
    path: string;
    title: string;    
    filters?: QueryFilter[];
    functions: any;
}

export interface DatatableOutput {
    action: DatatableOutputType;
    filters: QueryFilter[];
}

export enum DatatableOutputType {
    paginate,
    sort,
    search
}

export interface ActionLoading {
    id: string;
    type: string;
}
