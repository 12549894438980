import { AuthModel } from './auth.model';

export class UserModel extends AuthModel {
     
    id: number;
    username: string;
    password: string;
    fullname: string;
    pic: string; 

    uid: string;
    email: string;
    displayName: string;
    photoURL: string;
    emailVerified: boolean;
    auth: any;
    role: string;
    club: string;
    userInfo: any;
    permissions: string[];
    authenticated: boolean;
    
    errorMessage: string;

    setUser(_user: unknown) {
        const user = _user as UserModel;
        this.uid = user.uid;
        this.displayName = user.displayName || '';
        this.photoURL = user.photoURL || '';
        this.emailVerified = user.emailVerified || false;
        this.auth = user.auth || undefined;
        this.pic = user.pic || './assets/media/avatars/blank.png';    
    }

    setError(errorMessage: string) {    
        this.errorMessage = errorMessage;        
    }

    setAuth(auth: any) {    
        this.auth = auth || undefined;  
    }

    setRole(role: string) { this.role = role; }
    setClub(club: string) { this.club = club; }
    setPermissions(permissions: string[]) { this.permissions = permissions; }

    setUserInfo(userInfo: any) {    
        this.userInfo = userInfo || undefined;  
    }

}
