import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ThemeModeService } from './_metronic/partials/layout/theme-mode-switcher/theme-mode.service';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from './services/shared.service';
import { DataService } from './services/data.service';
import { lastValueFrom } from 'rxjs';
import { getAuthFromLocalStorage } from './modules/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
    // tslint:disable-next-line:component-selector
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'body[root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    private config: {version: string}; 
    static defaultLang: string = 'es';
    constructor(
        private modeService: ThemeModeService,
        private translate: TranslateService,
        private shared: SharedService,
        private data: DataService,
        private httpClient: HttpClient
    ) {
        this.setAppLanguage();
    }

    ngOnInit() {
        this.modeService.init();
        this.config = require('./../assets/config.json'); 
        console.log('Ubiko Admin', this.config.version);

        const headers = new HttpHeaders()
            .set('Cache-Control', 'no-cache')
            .set('Pragma', 'no-cache'); 
        
        this.httpClient
            .get<{ version: string }>("/assets/config.json", {headers})
            .subscribe(config => {                
            if (config.version !== this.config.version) {
                console.log('Ubiko Admin new release', config.version);
                location.reload(); 
            }
        });
    }

    async setAppLanguage() {
        this.translate.setDefaultLang(AppComponent.defaultLang);
        const lang = this.shared.getLanguage();
        if(lang) {
            this.translate.use(lang);
        }
        else {
            const userData = getAuthFromLocalStorage();
            let club = undefined;
            if(userData?.club) {
                let res: any = await lastValueFrom(this.data.load('clubs/' + userData?.club));
                club = res?.club;
            }         
            this.translate.use(this.getLang() || club?.language || AppComponent.defaultLang);
        }        
    }

    getLang() {
        let lang: string | undefined;
        const langs = this.shared.getLanguages();
        if(langs.find(l => l.lang == this.translate.getBrowserLang())) {
            lang = this.translate.getBrowserLang();
        }
        else {
            if(this.translate.getBrowserLang() && this.translate.getBrowserLang() != AppComponent.defaultLang) {
                lang = 'en';
            }
        }
        return lang;
    }
}
