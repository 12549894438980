import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../models/user.model';
import { environment } from '../../../../../environments/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ROLE_ADMIN, ROLE_MANAGER } from '../auth.service';

const API_USERS_URL = `${environment.apiUrl}users`;

@Injectable({
    providedIn: 'root',
})
export class AuthHTTPService {
    constructor(private http: HttpClient,
                public afAuth: AngularFireAuth) {}

    // public methods
    login(email: string, password: string): Promise<any> {
        return this.afAuth.signInWithEmailAndPassword(email, password);
    }

    logout(): Promise<any> {
        return this.afAuth.signOut();
    }

    async getIdToken(response: any, optHeaders?: OptionalHeaders[]): Promise<UserModel> {    
        let userData: any;
        await response.user.getIdTokenResult(true).then((token: any) => {  
            let currentUser = new UserModel();  
            if (token.claims) {
                if(token.claims.role) {
                    currentUser.setRole(token.claims.role);
                }
                if(token.claims.club) {
                    currentUser.setClub(token.claims.club);                  
                }
                if(token.claims.permissions) {
                    currentUser.setPermissions(token.claims.permissions);                  
                }
            }
            currentUser.setUser(response.user);
            currentUser.setAuth(token);
                         
            userData = currentUser;   
        });            
       
        if(userData.role != ROLE_ADMIN && userData.role != ROLE_MANAGER) {
            const profile = await lastValueFrom(this.getUserByToken(userData.auth.token, optHeaders));
            if(profile && profile.user) {
                userData.setUserInfo(profile.user); 
                console.log('profile.user', profile.user);
            }
        }
        return userData;   
    }

    async signInWithCustomToken(token: string): Promise<UserModel> {    
        let userData: any;
        await this.afAuth.signInWithCustomToken(token).then((userCredential: any) => {  
            userData = userCredential;
        });    
        return userData;   
    }

    // CREATE =>  POST: add a new user to the server
    createUser(user: UserModel): Observable<UserModel> {
        return this.http.post<UserModel>(API_USERS_URL, user);
    }

    // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
    forgotPassword(email: string): Observable<boolean> {
        return this.http.post<boolean>(`${API_USERS_URL}/forgot-password`, {
            email,
        });
    }

    public getUserByToken(token: string, optHeaders?: OptionalHeaders[]): Observable<any> {    
        return this.http.post<any>(`${API_USERS_URL}/getUserInfo`, null, this.getRequestOptions(token, optHeaders));
    }

    changeUser(token: string, uid: string): Observable<any> {    
        return this.http.post<any>(`${API_USERS_URL}/changeUser`, { uid }, this.getRequestOptions(token));
    }

    getRequestOptions(token: string, optHeaders?: OptionalHeaders[]): {} {
        let headers;
        if(optHeaders?.length) {
            let customHeaders = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'Access-Control-Allow-Origin': '*'
            };  
            optHeaders.forEach(h => {
                (customHeaders as any)[h.name] = h.value;
            }); 
            headers = new HttpHeaders(customHeaders);
        }
        else {
            headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'Access-Control-Allow-Origin': '*'
            });  
        }  
        return {headers};
    }
}

export interface OptionalHeaders {
    name: string;
    value: any;
}
