import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, takeWhile, ReplaySubject } from 'rxjs';
import { Club } from '../models/club.model';
import { Field } from '../models/field.model';
import { Player } from '../models/player.model';
import { Session } from '../models/session.model';
import { Team } from '../models/team.model';
import { DataService } from './data.service';
import { Kit } from '../models/kit.model';
import { AuthService, getAuthFromLocalStorage } from '../modules/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AlertModel } from '../_metronic/partials/layout/extras/dropdown-inner/notifications-inner/notifications-inner.component';
import { completeTech, Tech } from '../models/tech.model';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { DatatablePaginationService } from './datatable-pagination.service';
import { TimeWindowRange } from '../models/device-sync.model';
import { Distributor } from '../models/distributor.model';

export interface SessionWatcher {
    id: string,
    o: Observable<any | undefined>
}
export interface Modal {
    type: string
}
export interface SwitchIdentity {
    user: SwitchUser;
    auth: {
        uid: string;
        name: string;
        role: string;
        token: string;    
    }
}
export interface SwitchUser {
    uid: string;
    name: string;
    photo_url?: string;
    role: string;
    club?: string;
    token?: string;
}
export type PlayerType = Player | undefined;
export type TechType = Tech | undefined;
export type ModalType = Modal | undefined;
export type ClubType = Club | undefined;
export type SessionType = Session | undefined;
export type FieldType = Field | undefined;
export type KitType = Kit | undefined;
export type TeamType = Team | undefined;
export type DistributorType = Distributor | undefined;

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    
    constructor(private dataService: DataService, 
        private afs: AngularFirestore, 
        private router: Router,
        private auth: AuthService, 
        private datatable: DatatablePaginationService,
        private translate: TranslateService) {

        if(auth.isTech() && auth.getUid()) {
            this.storageTech(auth.getUid());
        }       
    }

    // Entidades   
    private _club$ = new BehaviorSubject<ClubType>(undefined); 
    private _team$ = new ReplaySubject<any>(1); 
    private _modal$ = new BehaviorSubject<ModalType>(undefined);
    private _teamEdit$ = new BehaviorSubject<any>(undefined);
    private _player$ = new BehaviorSubject<PlayerType>(undefined);
    private _tech$ = new BehaviorSubject<TechType>(undefined);
    private _session$ = new BehaviorSubject<SessionType>(undefined);
    private _field$ = new BehaviorSubject<FieldType>(undefined);
    private _kit$ = new BehaviorSubject<KitType>(undefined);
    private _entity$ = new BehaviorSubject<any>(undefined);
    private _actionEmitted$ = new BehaviorSubject<ActionEmitted | undefined>(undefined);
    private _distributor$ = new BehaviorSubject<DistributorType>(undefined);
    private _switchIdentity$ = new BehaviorSubject<SwitchIdentity | undefined>(undefined);    

    get club$() { return this._club$.asObservable(); }
    set club(club: Club) { this._club$.next(club); }

    // Para equipo seleccionado en sesion
    get team$() { return this._team$.asObservable(); }
    set team(team: any) { this._team$.next(team); }

    // Para suplantacion
    get switchIdentity$() { return this._switchIdentity$.asObservable(); }
    set switchIdentity(switchIdentity: SwitchIdentity) { this._switchIdentity$.next(switchIdentity); }

    // Para editar un equipo desde cualquier pantalla
    get teamEdit$() { return this._teamEdit$.asObservable(); }
    set teamEdit(teamEdit: any) { this._teamEdit$.next(teamEdit); }

    get player$() { return this._player$.asObservable(); }
    set player(player: Player) { this._player$.next(player); }

    get tech$() { return this._tech$.asObservable(); }
    set tech(tech: Tech) { this._tech$.next(tech); }

    get session$() { return this._session$.asObservable(); }
    set session(session: Session) { this._session$.next(session); }

    get modal$() { return this._modal$.asObservable(); }
    set modal(modal: Modal) { this._modal$.next(modal); }

    get field$() { return this._field$.asObservable(); }
    set field(field: Field) { this._field$.next(field); }

    get kit$() { return this._kit$.asObservable(); }
    set kit(kit: any) { this._kit$.next(kit); }

    get entity$() { return this._entity$.asObservable(); }
    set entity(entity: any) { this._entity$.next(entity); }

    get actionEmitted$() { return this._actionEmitted$.asObservable(); }
    set actionEmitted(actionEmitted: ActionEmitted) { this._actionEmitted$.next(actionEmitted); }

    get distributor$() { return this._distributor$.asObservable(); }
    set distributor(distributor: Distributor) { this._distributor$.next(distributor); }

    // Control de estado de pantallas
    private _pageLoaded$ = new BehaviorSubject<Boolean>(false);
    private _showPageLoader$ = new BehaviorSubject<Boolean>(false);

    get pageLoaded$() { return this._pageLoaded$.asObservable(); }
    set pageLoaded(pageLoaded: Boolean) { this._pageLoaded$.next(pageLoaded); }

    get showPageLoader$() { return this._showPageLoader$.asObservable(); }
    set showPageLoader(showPageLoader: Boolean) { this._showPageLoader$.next(showPageLoader); }

    // Monitorizacion en tiempo real    
    sessionsComputing: SessionWatcher[] = [];
    private _sessionsNotComputed$ = new BehaviorSubject<string[]>([]);

    get sessionsNotComputed$() { return this._sessionsNotComputed$.asObservable(); }
    set sessionsNotComputed(sessionsNotComputed: string[]) { this._sessionsNotComputed$.next(sessionsNotComputed); }

    // Ubiko Sync
    syncWindow: TimeWindowRange | undefined;
    getSyncWindow() { return this.syncWindow; }
    setSyncWindow(syncWindow: TimeWindowRange | undefined) { this.syncWindow = syncWindow; }

    get currentClub() {         
        let clubData = localStorage.getItem(this.auth.getUid() + '_club'); 
        if(clubData) {
            return JSON.parse(clubData) as Club;
        }
       return undefined;
    }

    get currentTeam() {         
        let teamData = localStorage.getItem(this.auth.getUid() + '_team'); 
        if(teamData) {           
            const team = JSON.parse(teamData) as any;       
            const club = this.currentClub;
            if(club && team.club.id == club.id) {                     
                return team;
            }            
        }
       return undefined;
    }

    get currentTeams() { 
        let teams = localStorage.getItem(this.auth.getUid() + '_teams'); 
        if(teams) {
            return JSON.parse(teams) as Team[];
        }
       return undefined;
    }  

    setTeamEdit(id?: string) { 
        if(id) {
            this.dataService.load('teams/' + id).subscribe({   
                next: (data) => {   
                    let wrapper = data;
                    this._teamEdit$.next(wrapper.team); 
                },
                error: (e) => {                
                    console.log('Error loading player', e.error.error);                 
                }    
            });   
        }
        else {
            this._teamEdit$.next(undefined); 
        }
    }

    setPlayer(id?: string, isNew: boolean = false) { 
        if(id) {
            this.dataService.load('players/' + id).subscribe({   
                next: (data) => {   
                    let wrapper = data;
                    this._player$.next(wrapper.player); 
                },
                error: (e) => {                
                    console.log('Error loading player', e.error.error);                 
                }    
            });   
        }
        else {
            this._player$.next(undefined); 
        }
    }

    setTech(id?: string, isNew: boolean = false) { 
        if(id) {
            this.dataService.load('techs/' + id).subscribe({   
                next: (data) => {   
                    let wrapper = data;
                    this._tech$.next(wrapper.tech); 
                },
                error: (e) => {                
                    console.log('Error loading tech', e.error.error);                 
                }    
            });   
        }
        else {
            this._tech$.next(undefined); 
        }
    }

    setField(id?: string, isNew: boolean = false) { 
        if(id) {
            this.dataService.load('fields/' + id).subscribe({   
                next: (data) => {   
                    let wrapper = data;
                    this._field$.next(wrapper.field); 
                },
                error: (e) => {                
                    console.log('Error loading field', e.error.error);                 
                }    
            });   
        }
        else {
            this._field$.next(isNew ? new Field() : undefined); 
        }
    }

    setKit(id?: string, isNew: boolean = false) { 
        if(id) {
            this.dataService.load('kits/' + id).subscribe({   
                next: (data) => {   
                    this._kit$.next(data.kit); 
                },
                error: (e) => {                
                    console.log('Error loading kit', e.error.error);                 
                }    
            });   
        }
        else {
            this._kit$.next(isNew ? new Kit() : undefined); 
        }
    }

    setSession(id?: string, isNew: boolean = false) { 
        if(id) {
            this.dataService.load('sessions/' + id).subscribe({   
                next: (data) => {   
                    let wrapper = data;
                    this._session$.next(wrapper.session); 
                },
                error: (e) => {                
                    console.log('Error loading session', e.error.error);                 
                }    
            }); 
        }
        else {
            this._session$.next(new Session()); 
        }       
    }

    setEntity(type: string, path: string, id?: string) { 
        if(id) {
            this.dataService.load(type + '/' + id).subscribe({   
                next: (data) => {   
                    let wrapper = data;
                    this._entity$.next(wrapper[path]); 
                },
                error: (e) => {                
                    console.log('Error loading entity', e.error.error);                 
                }    
            }); 
        }
        else {
            this._entity$.next({}); 
        }       
    }

    clearEntity() { 
        this._entity$.next(undefined); 
    }
    
    setCurrentClub(id?: string) {     
        if(id) {            
            this.dataService.load('clubs/' + id).subscribe({   
                next: (data) => {   
                    let club = data.club;
                    this._club$.next(club); 
                    //delete club.teams;
                    delete club.techs;
                    localStorage.setItem(this.auth.getUid() + '_club', JSON.stringify(club));
                },
                error: (e) => {                
                    console.log('Error loading club', e.error.error);                 
                }    
            });
        }
    }   

    setCurrentClubObject(club: any) {  
        this._club$.next(club); 
        //delete club.teams;
        delete club.techs;
        localStorage.setItem(this.auth.getUid() + '_club', JSON.stringify(club));
    }

    clearCurrentClub() {  
        this._club$.next(undefined); 
        localStorage.removeItem(this.auth.getUid() + '_club');
    }

    setSwitchIdentity(data: SwitchIdentity) { 
        localStorage.setItem('switchIdentity', JSON.stringify(data));    
        this._switchIdentity$.next(data); 
    }

    clearSwitchIdentity() { 
        localStorage.removeItem('switchIdentity');
        this._switchIdentity$.next(undefined); 
    }

    getSwitchIdentity() {   
        let switchIdentity = localStorage.getItem('switchIdentity'); 
        if(switchIdentity) {
            return JSON.parse(switchIdentity) as SwitchIdentity;
        }
        return undefined;
    }

    setCurrentTeam(team?: any) { 
        if(team) {
            let teamSimplified = {...team};
            if(teamSimplified.squad) {
                delete teamSimplified.squad.players;
                delete teamSimplified.squad.stats;
                delete teamSimplified.squad.stats_back;
                delete teamSimplified.squad.ubiko_stats;
                delete teamSimplified.squad.techs;
            }
            localStorage.setItem(this.auth.getUid() + '_team', JSON.stringify(teamSimplified)); 
            this._team$.next(team);         
        }
        else {
            localStorage.removeItem(this.auth.getUid() + '_team');
            this._team$.next(undefined); 
        }
    }

    getClubId() {
        const club = this.currentClub;
        if(!club) {
            let userData = getAuthFromLocalStorage();
            return userData?.club;
        }
        return club.id;
    }

    async setCurrentTeams() { 
        this.dataService.list('teams').subscribe({   
            next: (data) => {        
                let teams = data.teams as any[];
                let currentTeams: any = [];
                teams.forEach(team => {
                    currentTeams.push({
                        id: team.id,
                        name: team.name
                    });
                })
                localStorage.setItem(this.auth.getUid() + '_teams', JSON.stringify(currentTeams));
            },
            error: (e) => {                
                console.log('Error loading current teams', e.error.error);                 
            }    
        });
    }

    setModal(action: any) { 
        const modal: Modal = {
            type: action
        }
        this._modal$.next(action ? modal : undefined); 
    }

    setPageLoaded(loaded: Boolean) { 
        this._pageLoaded$.next(loaded);          
    }

    setShowPageLoader(showLoader: Boolean) { 
        this._showPageLoader$.next(showLoader); 
    }

    // Utilidades

    formatDate(date: NgbDateStruct) {
        if(date) {
            return date.year + '-' + date.month + '-' + date.day;
        }
        return undefined;
    }

    getFormattedDate(date: NgbDateStruct) {
        if(date) {
            return new Date(date.year + '-' + date.month + '-' + date.day);
        }
        return undefined;
    }

    getErrorFromResponse(response: any) {
        let errors: string = '';
        if(response && response instanceof Array) {
            for(let e of response) {
                errors = e.msg;
            }            
        }
        else if(response && response.error && response.error instanceof Array) {
            for(let e of response.error) {
                errors = e.msg;
            }            
        }
        else {
            if(response.message) {
                errors = response.message;
            }
            if(response.error) {
                errors = response.error;
            }
        }
        return errors;
    }

    count(map: any) {
        if(map) 
            return Object.keys(map).length;
        else 
            return 0;
    }

    getPercentCompareAvg(session: any, value1: number, stat: string) {
        if(session && session.ubiko_stats[session.typeInfo.type]) {
            let avg = session.ubiko_stats[session.typeInfo.type].totals.global_averages[stat];
            return Math.round(((value1 - avg) * 100) / avg);
        }
        return undefined;
    }

    formatSpeed(speed: number) {
        return Math.round((speed * 3.6) * 10) / 10;     
    }

    convertSpeedToKm(speed: number) {
        if(speed)
            return Math.round((speed * 3.6) * 10) / 10;     
        return undefined;     
    }

    convertSpeedToMs(speed: number) {
        if(speed)
            return speed / 3.6;
        return undefined;     
    }

    addSessionComputing(id_session: string) {
        let sessions = this._sessionsNotComputed$.value;
        sessions.push(id_session);
        this._sessionsNotComputed$.next(sessions);
    }

    subscribeSession(id: string) {        
        const sessionSnap = this.afs.doc<Session>('sessions/' + id);  
        if(sessionSnap) {
            this.sessionsComputing.push({
                id,
                o: sessionSnap.snapshotChanges()
            });
        }        
        this.sessionsComputing.forEach(sessionWatcher => {           
            const session$ = sessionWatcher.o;
            session$.pipe(
                    takeWhile(value => value.payload.data() && value.payload.data().status != Session.COMPUTED, true))
                .subscribe({
                next: (snapshot) => {
                    let data = snapshot.payload.data();
                    if(data && data.status == Session.COMPUTED) { 
                        if(this.router.url == '/sessions' || this.router.url == '/team/sessions') {
                            this.datatable.reloadEntity(data);  
                        }

                        let _sesionsComputing = this._sessionsNotComputed$.value;                              
                        this._sessionsNotComputed$.next(_sesionsComputing.filter(e => e != data.id));  
                        
                        this.pushAlert({
                            title: data.name,
                            description: this.translate.instant('sessions.calculated'),
                            time: new Date().toISOString(),
                            icon: 'bi-bar-chart-fill',
                            state: 'success',
                            link: '/sessions/' + data.id 
                        });     
                        if(!data.source) { // Excluir importadas
                            Swal.fire({
                                position: 'top-end',
                                backdrop: false,
                                width: '250px',
                                padding: '.5rem',
                                icon: 'success',
                                title: this.translate.instant('sessions.calculated.title'),
                                text: this.translate.instant('sessions.calculated'),
                                timer: 10000,
                                showClass: {
                                    popup: 'animate__animated animate__fadeInDown'
                                },
                                hideClass: {
                                    popup: 'animate__animated animate__fadeOutUp'
                                },
                                confirmButtonText: this.translate.instant('sessions.view_report')
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.router.navigate(['/sessions/' + data.id + '/report']);
                                }
                            });        
                        }          
                    }
                }
            }); 
        });       
    }

    getViewSelected(page: string) { 
        if(!this.auth.isAdmin()) {
            let view = localStorage.getItem(page + '-view'); 
            if(view) {
                return view;
            }
        }
        return 'list';
    }

    setViewSelected(page: string, view: string) { 
        localStorage.setItem(page + '-view', view); 
    }

    pushAlert(alert: AlertModel) {
        let listAlerts: AlertModel[] = [];
        let alerts = localStorage.getItem(this.auth.getUid() + '_alerts');      
        if(alerts) {
            listAlerts = JSON.parse(alerts) as AlertModel[];  
        }
        listAlerts.push(alert);
        localStorage.setItem(this.auth.getUid() + '_alerts', JSON.stringify(listAlerts));  
    }

    simplifyObject(o: any) {
        delete o.stats;
        delete o.ubiko_stats;
    }

    isProdMode() {
        return environment.production;
    }

    isTestMode() {
        return environment.test;
    }

    isDesaMode() {
        return environment.desa;
    }

    showError(response: any) {
        if(response.status != 401) {
            return true;
        }
        return false;
    }

    setLanguage(lang: string) { 
        localStorage.setItem('lang', lang); 
    }

    getLanguage() { 
        return localStorage.getItem('lang'); 
    }
    
    getLanguages() {
        return [
            {
                lang: 'en',
                name: this.translate.instant('language.english'),
                flag: './assets/media/flags/united-states.svg',
            },
            {
                lang: 'es',
                name: this.translate.instant('language.spanish'),
                flag: './assets/media/flags/spain.svg',
            },
            /*{
                lang: 'pt',
                name: this.translate.instant('language.portugues'),
                flag: './assets/media/flags/portugal.svg',
            },
            {
                lang: 'sz',
                name: this.translate.instant('language.switzerland'),
                flag: './assets/media/flags/switzerland.svg',
            },*/
        ];
    }   
    
    setModalDateLastSync(_date: string | undefined) { 
        if(_date) {
            localStorage.setItem(this.auth.getUid() + '_lastsync', _date); 
        }
        else {
            localStorage.removeItem(this.auth.getUid() + '_lastsync'); 
        }
    }

    getModalDateLastSync() { 
        return localStorage.getItem(this.auth.getUid() + '_lastsync'); 
    }

    storageTech(uid?: string) {     
        if(uid) {            
            this.dataService.load('techs/user/' + uid).subscribe({   
                next: (data) => {  
                    if(data?.tech) {
                        let tech = data?.tech as Tech;
                        completeTech(tech);
                        console.log('Storage tech', tech);
                        this.setTechLocalStorage(tech);
                    }
                },
                error: (e) => {                
                    console.log('Error loading tech', e.error.error);                 
                }    
            });
        }
    }

    setTechLocalStorage(tech: Tech) {      
        if(tech) {
            delete tech.squads;
            delete tech.teams;
            delete tech.send_invitation;
            delete tech.socialmedia;
            delete tech.user;
            delete tech._deleted;
            delete tech.photo_url;
            delete tech.phone;
            localStorage.setItem(this.auth.getUid() + '_tech', JSON.stringify(tech));
        }   
        else {
            localStorage.removeItem(this.auth.getUid() + '_tech');
        }       
    }

    getCurrentTech() {   
        let techData = localStorage.getItem(this.auth.getUid() + '_tech'); 
        if(techData) {
            return JSON.parse(techData) as Tech;
        }
        return undefined;
    }

    clearStorage(uid: string) {
        for (var i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if(key && (key.includes('_team') || key.includes('_club')) && !key.includes(uid)) {                
                localStorage.removeItem(key);        
            }
        }
    }
}

export interface ActionEmitted {
    id: string;
    action: string;
}

export const ACTION_EMITTED_CREATED = 'created';
export const ACTION_EMITTED_UPDATED = 'updated';
