import { Club } from "./club.model";

export class Field {         
    id: string;    
    name: string;   
    club: Club;
    coordinates: any;
    zones: any;
    team: any;
}
