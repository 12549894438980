// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  test: true,
  desa: false,
  firebase: {
    apiKey: "AIzaSyCCRPqMZ-w_n33fCn1umv27MuHbO2rmjbw",
    authDomain: "ubiko-test.firebaseapp.com",
    databaseURL: "https://ubiko-test-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "ubiko-test",
    storageBucket: "ubiko-test.appspot.com",
    messagingSenderId: "820245412385",
    appId: "1:820245412385:web:8fd5728d2c6386f9b38160",
    measurementId: "G-P8VNKX698D"
  },  
  appVersion: 'v1.0.0',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'https://europe-west1-ubiko-test.cloudfunctions.net/app/api/',
  realtimeUrl: '',
  appThemeName: 'Metronic',
  appPurchaseUrl: 'https://1.envato.market/EA4JP',
  appHTMLIntegration: 'https://preview.keenthemes.com/metronic8/demo1/documentation/base/helpers/flex-layouts.html',
  appPreviewUrl: 'https://preview.keenthemes.com/metronic8/angular/demo1/',
  appPreviewAngularUrl: 'https://preview.keenthemes.com/metronic8/angular/demo1',
  appPreviewDocsUrl: 'https://preview.keenthemes.com/metronic8/angular/docs',
  appPreviewChangelogUrl: 'https://preview.keenthemes.com/metronic8/angular/docs/changelog' 
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
