import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService, ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authService.currentUserValue;
        if (currentUser?.authenticated) {
            return true;
        }
        let queryParams = {};
        if(state.url !== '/dashboard') {
          queryParams = { returnUrl: state.url };
        }
        this.router.navigate(['/auth/login'], {
            queryParams
        });
        return false;
    }
}
